jQuery(document).ready(function($){
    // notification toggel
    $('#notificationDropdown').click(function() {
        $('.dropdown-menu').toggle();
    });

    var isItTouchDevice = ('ontouchstart' in document.documentElement);

    // var personalToggler = $('.nav-toggler-personal');
    var sidebarToggler = $('.nav-toggler-sidebar');
    var sidebarClose = $('.nav-toggler-sidebar-close');

    /*personalToggler.on('click',function(e){
        e.preventDefault();

        if(!personalToggler.hasClass('active')){
            $(this).addClass('active');
        }else{
            $(this).removeClass('active');
        }
    });*/

    sidebarToggler.on('click',function(e){
        e.preventDefault();

        if(!sidebarToggler.hasClass('active')){
            $(this).addClass('active');
        }else{
            $(this).removeClass('active');
        }
    });

    sidebarClose.on('click',function(e){
        e.preventDefault();

        if(!sidebarToggler.hasClass('active')){
            sidebarToggler.addClass('active');
        }else{
            sidebarToggler.removeClass('active');
        }
    });



    /** header dropdown js */
    let $headerNavProfilePhoto = $('.navbar-profile-header .navbar-nav .profile-photo');
    $headerNavProfilePhoto.on('click', function (e) {
        if(isItTouchDevice){
            e.preventDefault();

            let $dropdownContentElem = $(this).parents('li').first().find('.dropdown-content');
            if ($dropdownContentElem.length) {
                if ($dropdownContentElem.is(':visible')) {
                    $dropdownContentElem.hide();
                } else {
                    $dropdownContentElem.show();
                }
            }
        }
    });
});

document.addEventListener('DOMContentLoaded', function () {
    const notificationList = document.getElementById('notificationList');
    const unreadCountElem = document.getElementById('unread-count');
    const clearAllNotificationsBtn = document.getElementById('clearAllNotifications');

    if (notificationList && unreadCountElem) {
        // Fetch notifications periodically
        setInterval(fetchNotifications, 60000); // Every 1 minute

        // Fetch notifications on page load
        fetchNotifications();

        function fetchNotifications() {
            fetch('/account/notifications/ajax/latest')
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    populateNotifications(data.notifications);
                    updateUnreadCount(data.unreadCount);
                })
                .catch(error => console.log('Error fetching notifications:', error));
        }

        function populateNotifications(notifications) {
            notificationList.innerHTML = '';

            notifications.forEach(notification => {
                const notificationItem = document.createElement('li');
                // const time = getTimeDifference(notification.created_at);
                const time = notification.sent_time_ago_text;
                const iconClass = notification.viewed_at ? '' : 'fa-circle fa-unread';
                const markReadLink = notification.viewed_at ? '' : `<a href="#" class="mark-read" data-id="${notification.id}"><i class="fa fa-times-circle"></i></a>`;
                notificationItem.className = `notification-item ${notification.viewed_at ? '' : 'unread-notification'}`;
                notificationItem.setAttribute("data-id", notification.id);
                notificationItem.innerHTML = `
                    <div data-notificationid="${notification.id}" class="view-redirect-trigger">${notification.content}
                        <br/><span class="notification-time">${time}</span> 
                    </div>
                    <span class="un-read-icon">
                        <a href="#" class="mark-read-icon">
                            <i class="fa ${iconClass}"></i>
                        </a>    
                        ${markReadLink}
                    </span>
                `;
                notificationList.appendChild(notificationItem);
            });

            document.querySelectorAll('.mark-read').forEach(item => {
                item.addEventListener('click', function (e) {
                    e.preventDefault();
                    markNotificationRead(this.dataset.id);
                });
            });
        }

        function getTimeDifference(notificationTime) {
            const currentTime = new Date();
            const notificationDate = new Date(notificationTime);
            const timeDifference = currentTime - notificationDate;

            const seconds = Math.floor(timeDifference / 1000);
            const minutes = Math.floor(seconds / 60);
            const hours = Math.floor(minutes / 60);
            const days = Math.floor(hours / 24);

            if (days > 0) {
                return days + " day" + (days > 1 ? "s" : "") + " ago";
            } else if (hours > 0) {
                return hours + " hour" + (hours > 1 ? "s" : "") + " ago";
            } else if (minutes > 0) {
                return minutes + " minute" + (minutes > 1 ? "s" : "") + " ago";
            } else {
                return "Just now";
            }
        }

        function updateUnreadCount(count) {
            let existingCount = unreadCountElem.innerText;
            let existingCssClassToRemove = 'count'+existingCount;
            let newCssClassToAdd = 'count'+count;
            unreadCountElem.innerText = count;
            $(unreadCountElem).removeClass(existingCssClassToRemove).addClass(newCssClassToAdd);

            if (count > 0) {
                $('#notificationDropdown').removeClass('no-notifications');
            } else {
                $('#notificationDropdown').addClass('no-notifications');
            }

            const bellIcon = document.querySelector('.btn-notification .fa-bell');
            if (bellIcon) {
                if (count > 0) {
                    bellIcon.classList.add('text-success');
                } else {
                    bellIcon.classList.remove('text-success');
                }
            }
        }

        if (clearAllNotificationsBtn) {
            clearAllNotificationsBtn.addEventListener('click', function (e) {
                e.preventDefault();
                fetch('/account/notifications/ajax/clear-all', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                    }
                })
                .then(() => fetchNotifications());
            });
        }

        function markNotificationRead(id) {
            fetch(`/account/notifications/ajax/mark-read/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                }
            })
            .then(() => fetchNotifications());
        }
    } else {
        console.warn('Required elements for notifications are missing in the DOM.');
    }

    $('body').on('click', '.notificationList .notification-item .view-redirect-trigger', function () {
        let notificationId = $(this).attr('data-notificationid');
        let $parentNotificationListElem = $(this).parents('.notificationList').first();
        let viewUrl = $parentNotificationListElem.attr('data-viewurl');
        viewUrl = viewUrl.replace('ID', notificationId);
        location.href = viewUrl;
    });

    /** Main navigation menu dropdown-submenu */
    /*$('.primary-navigation-list .dropdown-trigger > a').click(function (e) {
        e.preventDefault();

        let $parentElem = $(this).parents('.dropdown-trigger').first();
        let $submenuUl = $parentElem.find('> ul').first();

        if ($submenuUl.is(':visible')) {
            $submenuUl.hide();
        } else {
            $submenuUl.show();
        }
    });*/
    $('.primary-navigation-list li.has-child').each(function (e) {
        let $childActiveElement = $(this).find('li.current');
        if ($childActiveElement.length > 0) {
            $(this).addClass('current');
        } else {
            $(this).removeClass('current');
        }
    });

    /** Apply select2 */
    if ($.fn.select2) {
        $('select.select2').select2();
        $('select.select2-modal').select2({
            dropdownParent: '.modal'
        });
    }

    disableModalButtons = function ($modalContent) {
        $modalContent.find('.modal-footer button').prop('disabled', true);
    };
    enableModalButtons = function ($modalContent) {
        $modalContent.find('.modal-footer button').prop('disabled', false);
    };

    /** Used to clear errors added just after form fields */
    clearErrors = function () {
        $('.invalid-feedback').remove();
        $('input.is-invalid').removeClass('is-invalid');
    };

    /**
     * Show/Hide modals integration
     */
    $('body').on('click', 'a[data-bs-toggle=modal]', function () {
        let targetId = $(this).attr('data-bs-target');
        $(targetId).modal('show');
    });
    $('body').on('click', '.modal .btn-close', function () {
        $(this).parents('.modal').first().modal('hide');
    });
    $('body').on('click', '.modal .btn-close-trigger', function () {
        $(this).parents('.modal').first().modal('hide');
    });
    $('body').on('click', '.modal', function (e) {
        let _this = $(this);
        if ($(e.target).hasClass('modal') && $(e.target).hasClass('modal-static')) {
            _this.find('.btn-close-trigger').click();
        }
    });

    /** Checkout and cart page license popup operations START */
    $(document).on('click', '.checkout-product-options-modal .update-cart-item', function () {
        let _this = $(this);
        let $modalContent = _this.parents('.modal-content').first();
        let dataToPost = $modalContent.find('select, input').serialize();
        let urlToPost = _this.attr('data-url');
        disableModalButtons($modalContent);

        let isCheckout = 0;
        let isCart = 0;
        if ($('body').hasClass('ecommerce') && $('body').hasClass('checkout')) {
            isCheckout = 1;
        }
        if ($('body').hasClass('ecommerce') && $('body').hasClass('cart')) {
            isCart = 1;
        }

        dataToPost = dataToPost+'&is_cart='+isCart+'&is_checkout='+isCheckout;

        $.ajax({
            url: urlToPost,
            method: 'post',
            data: dataToPost,
            success: function (response) {
                if (response.error == 1) {
                    Theme.showError(response.message);
                } else {
                    $modalContent.find('.btn-close-trigger').click();
                    setTimeout(function () {
                        if (isCart) {
                            $('.cart-form-container').html(response.cart_form_html);
                            $('.cross-sell-container').html(response.cross_sell_html);
                        }

                        if (isCheckout) {
                            /**
                             * Reload the cart totals by using the function calculateShippingFee()
                             */
                            if (typeof MainCheckout !== 'undefined') {
                                // console.log(MainCheckout);
                                // new MainCheckout().init().calculateShippingFee();
                                // calculateShippingFee();
                                MainCheckout.calculateShippingFeeStatic();
                            }
                        }
                    }, 500);
                }
            },
            complete: function () {
                enableModalButtons($modalContent);
                $modalContent.find('.btn-close-trigger').click();
            },
        });
    });

    $(document).on('change', '.checkout-product-options-modal .reload_product_options_trigger', function (e) {
        let _this = $(this);
        let $modalBody = _this.parents('.modal-body');
        $modalBody.find('.number_of_license_container').hide();

        let productId = _this.find('option:selected').attr('data-productid');

        $modalBody.find('.number_of_license_container.product-'+productId).show();
    });
    /** Checkout and cart page license popup operations END */
});
